import React from "react";

const Input = ({ handleSubmit, onChange, name, value }) => {
  return (
    <form
      className="input-filter"
      onSubmit={(e) => {
        e.preventDefault();
        if (value?.length > 0) {
          handleSubmit(value);
        }
      }}
    >
      <input
        type="text"
        placeholder="Wpisz ..."
        name={name}
        value={value}
        onChange={onChange}
      />
    </form>
  );
};

export default Input;
