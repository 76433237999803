export const CHANGE_SORT = "CHANGE_SORT";
export const SET_FILTER_VALUE = "SET_FILTER_VALUE";
export const SET_FAST_FILTER_VALUE = "SET_FAST_FILTER_VALUE";
export const SET_GLOBAL_FILTER_VALUE = "SET_GLOBAL_FILTER_VALUE";
export const REMOVE_FILTER_VALUE = "REMOVE_FILTER_VALUE";
export const DATA_LOADING = "DATA_LOADING";
export const DATA_SUCCESS = "DATA_SUCCESS";
export const DATA_FAILURE = "DATA_FAILURE";
export const SET_META_PAGINATION = "SET_META_PAGINATION";
export const SET_ACTIVE_SORT = "SET_ACTIVE_SORT";
export const FILTER_PRODUCTS = "FILTER_PRODUCTS";

const filters = [
  {
    name: "name_sku_or_product_group_sku",
    sort_id: "sku",
    values: [],
  },
  {
    name: "root_taxon_name",
    sort_id: "root_taxon_name",
    values: [],
  },
  {
    name: "producer_name",
    sort_id: "producer_name",
    values: [],
  },
  {
    name: "supplier_name",
    sort_id: "supplier_name",
    values: [],
  },
  {
    name: "quantity",
    sort_id: "quantity",
    values: [],
  },
  {
    name: "total_net_income",
    sort_id: "total_net_income",
    values: [],
  },
  {
    name: "total_net_profit",
    sort_id: "total_net_profit",
    values: [],
  },
  {
    name: "total_net_costs",
    sort_id: "total_net_costs",
    values: [],
  },
  {
    name: "margin",
    sort_id: "margin",
    values: [],
  },
  {
    name: "average_net_price",
    sort_id: "average_net_price",
    values: [],
  },
  {
    name: "average_net_profit",
    sort_id: "average_net_profit",
    values: [],
  },
  {
    name: "average_net_costs",
    sort_id: "average_net_costs",
    values: [],
  },
];

const createFastFilters = () => {
  const obj = {};
  filters.forEach((filter) => (obj[filter.name] = ""));
  return obj;
};

export const initial_state = {
  filters,
  global_filters: {
    range_from: null,
    range_to: null,
    range_type: null,
  },
  fast_filters: createFastFilters(),
  status: "invalid",
  active_sort: {
    column: "status",
    direction: "desc",
  },
  meta: {
    page: 1,
    total_pages: 1,
    per_page: 100,
    total_count: 1,
  },
  all_products: [],
  products: [],
};

const paginateProducts = ({ page, per_page, all_products }) => {
  const start = (page - 1) * per_page;
  const end = page * per_page;
  return all_products.slice(start, end);
};

const sortProducts = ({ products, column, direction }) => {
  if (
    [
      "quantity",
      "total_net_income",
      "total_net_profit",
      "total_net_costs",
      "average_net_price",
      "average_net_profit",
      "average_net_cost",
      "margin",
    ].includes(column)
  ) {
    const product_without_prices = products.filter(
      (props) => props[column] === null || parseFloat(props[column]) === 0
    );

    const product_with_prices = products
      .filter((props) => parseFloat(props[column]) > 0)
      .sort((a, b) =>
        direction === "desc"
          ? Number(b[column]) - Number(a[column])
          : Number(a[column]) - Number(b[column])
      );
    const all_products = [...product_with_prices, ...product_without_prices];

    return all_products;
  }

  if (["name"].includes(column)) {
    return products.sort((a, b) =>
      direction === "desc"
        ? b.name.localeCompare(a.name)
        : a.name.localeCompare(b.name)
    );
  }

  if (["sku"].includes(column)) {
    return products.sort((a, b) =>
      direction === "desc"
        ? b.sku.localeCompare(a.sku)
        : a.sku.localeCompare(b.sku)
    );
  }

  if (["producer_name"].includes(column)) {
    const product_without_producer_name = products.filter(
      ({ producer_name }) => !!!producer_name
    );

    const product_with_producer_name = products
      .filter(({ producer_name }) => !!producer_name)
      .sort((a, b) =>
        direction === "desc"
          ? b.producer_name.localeCompare(a.producer_name)
          : a.producer_name.localeCompare(b.producer_name)
      );

    const all_products = [
      ...product_with_producer_name,
      ...product_without_producer_name,
    ];

    return all_products;
  }

  if (["supplier_name"].includes(column)) {
    const product_without_supplier_name = products.filter(
      ({ supplier_name }) => !!!supplier_name
    );

    const product_with_supplier_name = products
      .filter(({ supplier_name }) => !!supplier_name)
      .sort((a, b) =>
        direction === "desc"
          ? b.supplier_name.localeCompare(a.supplier_name)
          : a.supplier_name.localeCompare(b.supplier_name)
      );

    const all_products = [
      ...product_with_supplier_name,
      ...product_without_supplier_name,
    ];

    return all_products;
  }

  if (["root_taxon_name"].includes(column)) {
    const product_without_root_taxon_name = products.filter(
      ({ root_taxon_name }) => !!!root_taxon_name
    );

    const product_with_root_taxon_name = products
      .filter(({ root_taxon_name }) => !!root_taxon_name)
      .sort((a, b) =>
        direction === "desc"
          ? b.root_taxon_name.localeCompare(a.root_taxon_name)
          : a.root_taxon_name.localeCompare(b.root_taxon_name)
      );

    const all_products = [
      ...product_with_root_taxon_name,
      ...product_without_root_taxon_name,
    ];

    return all_products;
  }

  return products;
};

const filterProducts = ({ products, filters, fast_filters }) => {
  const active_filters =
    filters?.filter((filter) => filter?.values?.length > 0) || [];

  // active filters
  for (const { values, name } of active_filters) {
    if (["name_sku_or_product_group_sku"].includes(name)) {
      const items = values.map(({ value }) => value);
      products = products.filter((product) => {
        return items.every((item) => {
          return (
            product.name.toLowerCase().includes(item.toLowerCase()) ||
            product.sku.toLowerCase().includes(item.toLowerCase()) ||
            product?.product_group_sku === item
          );
        });
      });
    }

    if (["producer_name"].includes(name)) {
      const items = values.map(({ value }) => value);
      products = products.filter((product) => {
        return items.every((item) => {
          return product?.producer_name
            ?.toLowerCase()
            .includes(item.toLowerCase());
        });
      });
    }

    if (["supplier_name"].includes(name)) {
      const items = values.map(({ value }) => value);
      products = products.filter((product) => {
        return items.every((item) => {
          return product?.supplier_name
            ?.toLowerCase()
            .includes(item.toLowerCase());
        });
      });
    }

    if (["root_taxon_name"].includes(name)) {
      const items = values.map(({ value }) => value);
      products = products.filter((product) => {
        return items.every((item) => {
          return product?.root_taxon_name
            ?.toLowerCase()
            .includes(item.toLowerCase());
        });
      });
    }

    if (
      [
        "quantity",
        "total_net_income",
        "total_net_profit",
        "total_net_costs",
        "average_net_price",
        "average_net_profit",
        "average_net_cost",
        "margin",
      ].includes(name)
    ) {
      const items = values.map(({ value }) => value);

      for (const item of items) {
        switch (true) {
          case item.startsWith(">="): {
            const value = item.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) >= Number(value);
            });
            break;
          }
          case item.startsWith(">"): {
            const value = item.slice(1).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) > Number(value);
            });
            break;
          }
          case item.startsWith("<="): {
            const value = item.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) <= Number(value);
            });
            break;
          }
          case item.startsWith("<"): {
            const value = item.slice(1).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) < Number(value);
            });
            break;
          }
          case item === "brak": {
            products = products.filter((product) => {
              return !product[name] || parseFloat(product[name]) === 0;
            });
            break;
          }
          case item.endsWith("lower_than_suggested"): {
            products = products.filter((product) => product[item]);
            break;
          }
          default: {
            products = products.filter((product) => {
              return Number(product[name]) === Number(item.replace(",", "."));
            });
          }
        }
      }
    }
  }

  // fast filters

  Object.keys(fast_filters).forEach((name) => {
    const value = fast_filters[name];
    if (value) {
      if (["name_sku_or_product_group_sku"].includes(name)) {
        products = products.filter((product) => {
          return (
            product.name.toLowerCase().includes(value.toLowerCase()) ||
            product.sku.toLowerCase().includes(value.toLowerCase()) ||
            product?.product_group_sku === value
          );
        });
      }

      if (["producer_name"].includes(name)) {
        products = products.filter((product) => {
          return product?.producer_name
            ?.toLowerCase()
            .includes(value.toLowerCase());
        });
      }

      if (["supplier_name"].includes(name)) {
        products = products.filter((product) => {
          return product?.supplier_name
            ?.toLowerCase()
            .includes(value.toLowerCase());
        });
      }

      if (["root_taxon_name"].includes(name)) {
        products = products.filter((product) => {
          return product?.root_taxon_name
            ?.toLowerCase()
            .includes(value.toLowerCase());
        });
      }

      if (
        [
          "quantity",
          "total_net_income",
          "total_net_profit",
          "total_net_costs",
          "average_net_price",
          "average_net_profit",
          "average_net_cost",
          "margin",
        ].includes(name)
      ) {
        switch (true) {
          case value.startsWith(">="): {
            const new_value = value.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) >= Number(new_value);
            });
            break;
          }
          case value.startsWith(">"): {
            const new_value = value.slice(1).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) > Number(new_value);
            });
            break;
          }
          case value.startsWith("<="): {
            const new_value = value.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) <= Number(new_value);
            });
            break;
          }
          case value.startsWith("<"): {
            const new_value = value.slice(1).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) < Number(new_value);
            });
            break;
          }
          case value === "brak": {
            products = products.filter((product) => {
              return !product[name] || parseFloat(product[name]) === 0;
            });
            break;
          }
          case value.endsWith("lower_than_suggested"): {
            products = products.filter((product) => product[value]);
            break;
          }
          default: {
            products = products.filter((product) => {
              return Number(product[name]) === Number(value.replace(",", "."));
            });
          }
        }
      }
    }
  });

  return products;
};

const calculateAverageData = (products) =>
  products.map((item) => {
    const average_net_price = item.total_net_income / item.quantity;
    const average_net_profit = item.total_net_profit / item.quantity;
    const average_net_cost = item.total_net_costs / item.quantity;
    const margin = (item.total_net_profit * 100) / item.total_net_income;

    return {
      ...item,
      average_net_price,
      average_net_profit,
      average_net_cost,
      margin,
    };
  });

const mainListReducer = (state = initial_state, { type, payload }) => {
  switch (type) {
    case DATA_LOADING: {
      return {
        ...state,
        status: "loading",
      };
    }
    case DATA_FAILURE: {
      return {
        ...state,
        status: "failure",
      };
    }
    case DATA_SUCCESS: {
      const all_products = calculateAverageData(payload.data);
      const filtered_products = filterProducts({
        products: all_products,
        filters: state.filters,
        fast_filters: state.fast_filters,
      });

      const products = paginateProducts({
        all_products: filtered_products,
        per_page: state.meta.per_page,
        page: state.meta.page,
      });
      return {
        ...state,
        status: "success",
        all_products,
        products,
        meta: {
          ...state.meta,
          total_pages: Math.ceil(all_products.length / state.meta.per_page),
          total_count: all_products.length,
        },
      };
    }
    case SET_FILTER_VALUE: {
      const filters = [...state.filters];

      const index = filters.indexOf(
        filters?.find((filter) => filter?.name === payload.name)
      );
      if (index > -1) {
        filters[index].values = [
          ...filters[index].values.filter(
            ({ value }) => value !== payload.value
          ),
          { value: payload.value, label: payload.label },
        ];
      }
      return {
        ...state,
        meta: { ...state.meta, page: 1 },
        filters,
      };
    }
    case SET_FAST_FILTER_VALUE: {
      const fast_filters = { ...state.fast_filters };

      fast_filters[payload.name] = payload.value;

      return {
        ...state,
        meta: { ...state.meta, page: 1 },
        fast_filters,
      };
    }
    case SET_GLOBAL_FILTER_VALUE: {
      const global_filters = { ...state.global_filters, ...payload };

      return {
        ...state,
        meta: { ...state.meta, page: 1 },
        global_filters,
      };
    }
    case REMOVE_FILTER_VALUE: {
      const filters = [...state.filters];
      const index = filters.indexOf(
        filters.find((filter) => filter?.name === payload.name)
      );
      if (index > -1) {
        filters[index].values = [
          ...filters[index].values.filter(
            ({ value }) => value !== payload.value
          ),
        ];
      }
      return { ...state, filters };
    }
    case SET_META_PAGINATION: {
      const filtered_products = filterProducts({
        products: [...state.all_products],
        filters: state.filters,
        fast_filters: state.fast_filters,
      });

      const all_products = sortProducts({
        products: filtered_products,
        ...state.active_sort,
      });

      const products = paginateProducts({
        all_products,
        per_page: payload.per_page,
        page: payload.page,
      });

      return {
        ...state,
        meta: {
          ...state.meta,
          per_page: payload.per_page,
          page: payload.page,
          total_pages: Math.ceil(all_products.length / payload.per_page),
          total_count: all_products.length,
        },
        products,
      };
    }
    case CHANGE_SORT: {
      const filtered_products = filterProducts({
        products: [...state.all_products],
        filters: state.filters,
        fast_filters: state.fast_filters,
      });
      const all_products = sortProducts({
        products: filtered_products,
        column: payload.column,
        direction: payload.direction,
      });

      const products = paginateProducts({
        all_products,
        per_page: state.meta.per_page,
        page: 1,
      });

      return {
        ...state,
        active_sort: {
          column: payload.column,
          direction: payload.direction,
        },
        meta: {
          ...state.meta,
          page: 1,
        },
        products,
      };
    }
    case FILTER_PRODUCTS: {
      const filtered_products = filterProducts({
        products: [...state.all_products],
        filters: state.filters,
        fast_filters: state.fast_filters,
      });
      const all_products = sortProducts({
        products: filtered_products,
        ...state.active_sort,
      });

      const products = paginateProducts({
        all_products,
        per_page: state.meta.per_page,
        page: 1,
      });

      return {
        ...state,
        products,
        meta: {
          ...state.meta,
          page: 1,
          total_pages: Math.ceil(all_products.length / state.meta.per_page),
          total_count: all_products.length,
        },
      };
    }
    default:
      return state;
  }
};

export default mainListReducer;
