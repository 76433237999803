import React, { useState } from "react";
import Modal from "components/Modal";
import { FormikSelect } from "components";
import { Button } from "expano-components";
import { Form, Formik } from "formik";
import useImport from "hooks/useImport";
import { useNavigate } from "react-router-dom";
import shipper_options from "utils/options/shippers";
import DropZone from "react-dropzone-uploader";
import { getBase64Data } from "utils/helpers";

const ImportBillingsModal = ({ 
  handleClose,
  accept = "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  file_formats = ".csv,.xls,.xlsx",
}) => {
  const navigate = useNavigate();

  const { is_saving, create } = useImport({
    path: "/waybills/import",
    onSuccess: () => {
      navigate(`/waybills`);
      handleClose();
    },
    onError: (err) => {
      handleClose();
    },
    success_message: "Pomyślnie zakolejkowane procesowanie pliku specyfikacji. Rezultaty analizy pliku będą widoczne za parenaście minut",
  });

  const [file_data, setFileData] = useState(null);

  const onSubmit = (values) => create({ ...values, file_data });

  const handleChangeStatus = ({ file }, status) => {
    if (status === "done") {
      new Promise(async () => {
        const file_data = await getBase64Data(file);
        setFileData(file_data);
      })
    }
  };

  return (
    <Formik
      initialValues={{
        shipper: null,
      }}
      onSubmit={onSubmit}
    >
      {({ dirty, submitForm, isValid }) => (
        <Modal
          handleClose={handleClose}
          header={{ title: "Importuj plik rozliczeń" }}
          footer={
            <Button
              disabled={!dirty || is_saving || !isValid}
              type="save"
              text={is_saving ? "Proszę czekać..." : "Utwórz"}
              onClick={submitForm}
            />
          }
        >
          <Form>
            <FormikSelect
              label="Przewoźnik"
              options={shipper_options}
              name="shipper"
            />
            <DropZone
              onChangeStatus={handleChangeStatus}
              SubmitButtonComponent={null}
              maxFiles={1}
              multiple={false}
              canCancel={false}
              inputContent={() => (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ marginBottom: 5 }}
                  >
                    <path
                      fill="#5d78ff"
                      opacity="0.3"
                      d="M2,13a1.075,1.075,0,0,1,1-1,1.075,1.075,0,0,1,1,1v5a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V13a1,1,0,0,1,2,0v5a4,4,0,0,1-4,4H6a4,4,0,0,1-4-4Z"
                    />
                    <rect
                      fill="#5d78ff"
                      opacity="0.3"
                      width="2"
                      height="14"
                      rx="1"
                      transform="translate(11 2)"
                    />
                    <path
                      fill="#5d78ff"
                      d="M12.036,3.378,7.707,7.707A1,1,0,0,1,6.293,6.293l5-5a1,1,0,0,1,1.376-.036l5,4.5a1,1,0,0,1-1.338,1.487Z"
                    />
                  </svg>
                  <p className="text lg">
                    Przenieś plik tutaj lub kliknij, aby go dodać.
                  </p>
                  <p className="text">Format plików to {file_formats}</p>
                </>
              )}
              inputWithFilesContent="Dodaj"
              styles={{
                dropzone: {
                  width: 384,
                  minHeight: 140,
                  padding: 10,
                  border: "2px dashed #EBEDF2",
                  borderRadius: 0,
                  flexDirection: "row",
                  flexFlow: "wrap",
                  justifyContent: "center",
                },
                inputLabel: {
                  flexDirection: "column",
                  justifyContent: "center",
                },
                previewImage: {
                  maxHeight: "initial",
                  maxWidth: "calc(100% - 15px)",
                },
                preview: {
                  padding: 10,
                  border: "none",
                  display: "inline-flex",
                  width: "auto",
                  alignItems: "flex-start",
                },
                inputLabelWithFiles: {
                  margin: "auto",
                  backgroundColor: "#44B2F1",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
              accept={accept}
              disabled={(files) =>
                files.some((f) =>
                  ["preparing", "getting_upload_params", "uploading"].includes(
                    f.meta.status
                  )
                )
              }
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default ImportBillingsModal;
