const wisetrade_payment_methods = [
  "Przelew bankowy",
  "PayU (ALLCZ-DE",
  "PayU (ALLCZ-DZ)",
  "PayU (ALLCZ-GI)",
  "PayU (SK-DZ)",
  "Przelewy24 (ALLCZ-DE",
  "Przelewy24 (ALLCZ-DZ",
  "Przelewy24 (ALLCZ-GI",
  "Przelewy24 (ALLHU-DE",
  "Przelewy24 (ALLHU-DZ",
  "Przelewy24 (ALLHU-GI",
  "Przelewy24 (ALLSK-DE",
  "Przelewy24 (ALLSK-DZ",
  "Przelewy24 (ALLSK-GI",
  "PayU (ALL-DE)",
  "PayU (ALL-DZ)",
  "PayU (ALL-GI)",
  "PayU (SK-DZ)",
  "Przelewy24 (ALL-DE)",
  "Przelewy24 (ALL-DZ)",
  "Przelewy24 (ALL-GI)",
  "PayU (SK-DE)",
  "PayU (ALL-OG)",
  "PayU (ALL-DS)",
  "Przelewy24 (ALL-OG)",
  "Przelewy24 (ALL-DS)",
  "Pobranie DPD",
  "Pobranie UPS",
  "Pobranie GLS",
  "Pobranie InPost",
  "eBay (DE)",
  "Amazon (DE)",
  "Amazon (ES)",
  "Amazon (FR)",
  "Amazon (IT)",
  "Amazon (PL)",
  "Amazon (BE)",
  "Amazon (NL)",
  "Amazon (SE)",
  "PayU (ER)",
  "Pobranie Poczta",
  "Pob. PayU (ALLCZ-DE",
];

const tradecron_payment_methods = [
  "Przelew bankowy",
  "PayU (ALLCZ-IG)",
  "Przelewy24 (ALLCZ-IG",
  "PayU (SK-IGPL)",
  "Przelewy24 (ALL-IG)",
  "PayU (ALL-IG)",
  "Przelewy24 (ALL-TI)",
  "PayU (ALL-TI)",
  "Przelewy24 (ALL-SE)",
  "PayU (ALL-SE)",
  "Przelewy24 (ALL-SD)",
  "PayU (ALL-SD)",
  "Przelewy24 (ALL-SI)",
  "PayU (ALL-SI)",
  "Przelewy24 (ALL-PD)",
  "PayU (ALL-PD)",
  "Przelewy24 (ALL-BE)",
  "Przelewy24 (ALLCZ-BE",
  "Przelewy24 (ALLSK-BE",
  "PayU (ALL-BE)",
  "PayU (ALLCZ-BE)",
  "Pobranie InPost",
  "Pobranie GLS",
  "Pobranie DPD",
  "Pobranie UPS",
  "eBay (DE)",
  "Amazon (DE)",
  "Amazon (ES)",
  "Amazon (FR)",
  "Amazon (IT)",
  "Amazon (PL)",
  "Amazon (BE)",
  "Amazon (NL)",
  "Amazon (SE)",
  "Pobranie Poczta",
  "Pob. PayU (ALLCZ-IG",
  "Pob. PayU (ALLCZ-BE",
  "Pob. PayU (ALLSK-BE",
  "Pob. PayU (ALLSK-IG",

  "PayU (ER)",
];

const getPaymentMethods = (expano_root_company_id) => {
  let data = [];
  if (expano_root_company_id === 1) {
    data = tradecron_payment_methods;
  }
  if (expano_root_company_id === 2) {
    data = wisetrade_payment_methods;
  }

  return data.map((method) => ({
    label: method,
    value: method,
  }));
};

export default getPaymentMethods;
