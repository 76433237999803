import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { api, getApiErrorMessage } from "utils/api";

const useImport = ({
  path,
  onSuccess,
  onError,
  success_message = "Zaimportowano plik pomyślnie",
}) => {
  const { isPending: is_saving, mutateAsync: create } = useMutation({
    mutationFn: (values) => api.post(path, { ...values }),
    onSuccess: ({ data }) => {
      toast(success_message, {
        type: "success",
      });
      onSuccess?.(data);
    },
    onError: (err) => {
      toast(getApiErrorMessage(err), {
        type: "error",
      });
      onError?.();
    },
  });
  return { is_saving, create };
};

export default useImport;
