import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import { useProductsAnalyze } from "../../context/provider";
import { Select } from "expano-components";
import moment from "moment";

const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <rect
      fill="#595d6e"
      width="15.833"
      height="3.333"
      rx="1"
      transform="translate(1.667 4.167)"
    />
    <rect
      fill="#595d6e"
      opacity="0.3"
      width="15.833"
      height="8.333"
      rx="1"
      transform="translate(1.667 9.167)"
    />
  </svg>
);

const date_options = [
  { label: "Wczoraj", value: "yesterday" },
  { label: "Dzisiaj", value: "today" },
  { label: "Ostatnie 7 dni", value: "last_7_days" },
  { label: "Ostatnie 14 dni", value: "last_14_days" },
  { label: "Ostatnie 30 dni", value: "last_30_days" },
  { label: "Ten miesiąc", value: "current_month" },
  { label: "Zeszły miesiąc", value: "last_month" },
  { label: "Ten rok", value: "this_year" },
  { label: "Zeszły rok", value: "last_year" },
];

const getHistogramDates = (value) => {
  switch (value) {
    case "yesterday":
      return {
        range_from: moment().subtract(1, "day").startOf("day"),
        range_to: moment().subtract(1, "day").endOf("day"),
      };
    case "today":
      return {
        range_from: moment().startOf("day"),
        range_to: moment().endOf("day"),
      };
    case "last_7_days":
      return {
        range_from: moment().subtract(7, "day").startOf("day"),
        range_to: moment().endOf("day"),
      };
    case "last_14_days":
      return {
        range_from: moment().subtract(14, "day").startOf("day"),
        range_to: moment().endOf("day"),
      };
    case "last_30_days":
      return {
        range_from: moment().subtract(30, "day").startOf("day"),
        range_to: moment().endOf("day"),
      };

    case "current_month":
      return {
        range_from: moment().startOf("month"),
        range_to: moment().endOf("month"),
      };
    case "last_month":
      return {
        range_from: moment().subtract(1, "month").startOf("month"),
        range_to: moment().subtract(1, "month").endOf("month"),
      };
    case "this_year":
      return {
        range_from: moment().startOf("year"),
        range_to: moment().endOf("year"),
      };
    case "last_year":
      return {
        range_from: moment().subtract(1, "year").startOf("year"),
        range_to: moment().subtract(1, "year").endOf("year"),
      };
    default:
      break;
  }
};

const GlobalFilters = () => {
  const {
    state: {
      global_filters: { range_from, range_to, range_type },
    },
    setGlobalFiltersValue,
  } = useProductsAnalyze();

  return (
    <div className="p-20" style={{ position: "relative", zIndex: 5 }}>
      <div className="row">
        <div className="box-2">
          <Select
            label="Zakres"
            handleChange={(value) =>
              setGlobalFiltersValue({
                range_type: value,
                ...getHistogramDates(value),
              })
            }
            options={date_options}
            value={date_options.find(({ value }) => value === range_type)}
          />
        </div>
        <div className="box-2">
          <div className="form-group">
            <div className="inp-wrapper">
              <label className="label-form">Data</label>
              <div className="calendar-group">
                <DateTimeRangePicker
                  disableClock={true}
                  calendarIcon={<CalendarIcon />}
                  onChange={(value) =>
                    setGlobalFiltersValue({
                      range_from: value?.[0] || null,
                      range_to: value?.[1] || null,
                    })
                  }
                  value={[range_from, range_to]}
                  locale="pl-PL"
                  format="yyyy-MM-dd"
                  maxDetail="minute"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalFilters;
