import Layout from "components/Layout";
import { useOrderStatuses } from "context/providers/order_statuses";
import StatusesList from "./components/StatusesList";
import PagePanel from "./components/PagePanel";

const OrderLayout = ({ show_statuses, ...props }) =>
  show_statuses ? (
    <OrderLayoutWithStatuses {...props} />
  ) : (
    <OrderLayoutWithoutStatuses {...props} />
  );

const OrderLayoutWithStatuses = ({ page, status_id, children }) => {
  const { data: statuses } = useOrderStatuses();
  return (
    <Layout>
      <PagePanel data={page} />
      <div className="orders-layout">
        <StatusesList status_id={status_id} data={statuses} />
        <div className="order-box">{children}</div>
      </div>
    </Layout>
  );
};

const OrderLayoutWithoutStatuses = ({ page, children }) => (
  <Layout>
    <PagePanel data={page} />
    <div className="orders-layout">
      <div className="order-box">{children}</div>
    </div>
  </Layout>
);

export default OrderLayout;
