import { ReactComponent as ShopIcon } from "icons/shop.svg";
import { ReactComponent as WaybillsIcon } from "icons/waybills.svg";
import { ReactComponent as DashboardIcon } from "icons/dashboard.svg";
import { ReactComponent as ChartIcon } from "icons/chart.svg";

const nav = [
  {
    name: "Zamówienia",
    type: "single",
    icon: <ShopIcon />,
    items: [
      {
        name: "Lista zamówień",
        path: "/orders",
        protected: false,
      },
    ],
  },
  {
    name: "Statystyki",
    type: "single",
    icon: <DashboardIcon />,
    items: [
      {
        name: "Statystyki",
        path: "/stats",
        protected: true,
      },
    ],
  },
  {
    name: "Listy przewozowe",
    type: "single",
    icon: <WaybillsIcon />,
    items: [
      {
        name: "Listy przewozowe",
        path: "/waybills",
        protected: true,
      },
    ],
  },
  {
    name: "Analiza",
    type: "single",
    icon: <ChartIcon />,
    items: [
      {
        name: "Analiza",
        path: "/analyze/products",
        protected: true,
      },
    ],
  },
];

export default nav;
