import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import {
  faInfo,
} from "@fortawesome/free-solid-svg-icons";

import renderPrice from "utils/renderPrice";
import renderDate from "utils/renderDate";

const Item = ({
  id,
  created_at,
  courier_name,
  shipment_id,
  package_numbers,
  sub_packages,
  company_id,
  order_id,
  shipping_billing_id,
  dimensions,
  weights,
  gauge_weights,
  price,
  settlement_amount,
  surcharge_amount,
  additional_information,
}) => {
  return (
    <>
      <td>
        <Link to={`/waybills/${id}`} className="waybill-identifier">
          {id}
        </Link>
      </td>
      <td>
        <p>{renderDate(created_at)}</p>
      </td>
      <td>
        <p>{courier_name}</p>
      </td>
      <td>
        {shipping_billing_id}
      </td>
      <td>
        <Link to={`/orders/${order_id}`} className="order-identifier">
          {order_id}
        </Link>
      </td>
      <td>
        {sub_packages.map(({package_number}) => 
          <p>{package_number}</p>
        )}
      </td>
      <td>
        {sub_packages.map((sub_package) => 
          <p>{sub_package.width}x{sub_package.height}x{sub_package.weight}</p>
        )}
      </td>
      <td>
        {sub_packages.map(({weight}) => 
          <p>{weight}</p>
        )}
      </td>
      <td>
        {sub_packages.map(({gauge_weight}) => 
            <p>{gauge_weight}</p>
        )}
      </td>
      <td>
        {price}
      </td>
      <td>
        {renderPrice(settlement_amount)}
      </td>
      <td>
        {renderPrice(surcharge_amount)}
      </td>
      <td>
        {additional_information && (
          <>
            <span
              data-tooltip-id={`additional-information-${id}`}
              className={classNames("label label-icon label--info")}
            >
              <FontAwesomeIcon icon={faInfo} color="#fff" />
            </span>
            <Tooltip id={`additional-information-${id}`}>
              {additional_information}
            </Tooltip>
          </>
        )}
      </td>
    </>
  );
};
export default Item;
