export const columns = [
  {
    label: "ID",
    name: "id",
    sortable: true,
  },
  {
    label: "Data nadania",
    name: "created_at",
    sortable: true,
    default_sort: true,
  },
  {
    label: "Spedytor",
    name: "courier_name",
    sortable: true,
  },
  {
    label: "Numer faktury",
    name: "shipping_billing_id",
    sortable: true,
  },
  {
    label: "Numer zamówienia",
    name: "order_id",
    sortable: true,
  },

  {
    label: "Numery przesyłek",
    name: "package_numbers",
    sortable: false,
  },
  {
    label: "Wymiary",
    extra: "cm",
    name: "dimensions",
    sortable: false,
  },
  {
    label: "Waga",
    extra: "kg",
    name: "weights",
    sortable: false,
  },
  {
    label: "Waga gabarytowa",
    extra: "kg",
    name: "gauge_weights",
    sortable: false,
  },
  {
    label: "Kwota prognozowana",
    extra: "zł",
    name: "price",
    sortable: true,
  },

  {
    label: "Kwota rozliczeniowa",
    extra: "zł",
    name: "settlement_amount",
    sortable: true,
  },
  {
    label: "Kwota dopłaty",
    extra: "zł",
    name: "surcharge_amount",
    sortable: true,
  },
  {
    label: "Szczegóły",
    name: "additional_information",
    sortable: false,
  },
];

export const table_filters = [];

export const main_filters = [
  {
    type: "input",
    label: "ID listu przewozowego",
    name: "id",
    search_type: "matches",
  },
  {
    type: "multi-select",
    label: "Spedytor",
    name: "courier_name",
    search_type: "in",
    options: [],
  },
  {
    type: "date",
    label: "Data nadania",
    name: "created_at",
    search_type: "range",
  },
  {
    type: "input",
    label: "Numery przesyłek",
    name: "package_number",
    search_type: "matches",
  },
  {
    type: "select",
    label: "Dopłata do przesyłki",
    name: "surcharge",
    search_type: "matches",
    options: [
      { label: "Tak", value: 'true' },
      { label: "Nie", value: 'false' },
    ],
  },
  {
    type: "select",
    label: "Posiada fakture",
    name: "has_invoice",
    search_type: "matches",
    options: [
      { label: "Tak", value: 'true' },
      { label: "Nie", value: 'false' },
    ],
  },
];
