import Modal from "components/Modal";
import { FormikInput, FormikSelect } from "components";
import { Button } from "expano-components";
import { Form, Formik } from "formik";
import useCreate from "hooks/useCreate";
import useGet from "hooks/useGet";
import { useNavigate } from "react-router-dom";

import clone_order_schema from "utils/validations/clone_order_schema";

const CloneOrderModal = ({ handleClose, order_id }) => {
  const navigate = useNavigate();
  const { data, is_loading } = useGet({
    path: "/channels",
    query: ["channels"],
    search: {
      "q[platform_type_eq]": "Wsf::Platform",
    },
  });

  const channel_options =
    data?.map(({ identifier, id }) => ({ label: identifier, value: id })) || [];

  const { is_saving, create } = useCreate({
    path: `/orders/${order_id}/clone`,
    onSuccess: ({ id }) => {
      handleClose();
      navigate(`/orders/${id}`);
    },
    success_message: "Utworzono kopię zamówienia",
  });

  const onSubmit = (values) => create(values);

  return (
    <Formik
      validationSchema={clone_order_schema}
      initialValues={{
        channel_id: null,
        client_email: "",
        discount: 5,
      }}
      onSubmit={onSubmit}
    >
      {({ dirty, submitForm, isValid }) => (
        <Modal
          handleClose={handleClose}
          header={{ title: "Utwórz kopię zamówienia" }}
          footer={
            <Button
              disabled={!dirty || is_loading || is_saving || !isValid}
              type="save"
              text={is_saving ? "Proszę czekać..." : "Utwórz"}
              onClick={submitForm}
            />
          }
        >
          <Form>
            <FormikSelect
              label="Źródło"
              options={channel_options}
              name="channel_id"
            />
            <FormikInput label="E-mail" name="client_email" type="email" />
            <FormikInput label="Rabat" name="discount" type="number" postfix="%" />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default CloneOrderModal;
