import React from "react";
import classNames from "classnames";

import { ReactComponent as ArrowIcon } from "icons/sort-arrow.svg";
import { useProductsAnalyze } from "../../../../context/provider";
import Select from "./components/Select";

const predefined_sort_options = [
  { label: "większa od", value: ">" },
  { label: "większa lub równa", value: ">=" },
  { label: "mniejsza od", value: "<" },
  { label: "mniejsza lub równa", value: "<=" },
  { label: "równa 0", value: "brak" },
];

const checkDirectionToChange = ({ is_current_column, active_direction }) => {
  if (is_current_column) {
    return active_direction === "asc" ? "desc" : "asc";
  }
  return "desc";
};

const TableSort = () => {
  const {
    sort,
    state: { active_sort },
    changeSort,
    setFilterValue,
  } = useProductsAnalyze();

  return (
    <thead className="table-sort">
      <tr>
        {sort.map(
          ({
            label = "",
            name = "",
            sortable = true,
            style = {},
            predefined_sorts,
          }) =>
            sortable ? (
              <th style={style} key={name}>
                {name === "name_sku_or_product_group_sku" ? (
                  <div className="sort-group">
                    <button
                      type="button"
                      onClick={() =>
                        changeSort({
                          column: "sku",
                          direction: checkDirectionToChange({
                            is_current_column: active_sort.column === "sku",
                            active_direction: active_sort.direction,
                          }),
                        })
                      }
                      className={classNames("sort-button", {
                        active: active_sort.column === "sku",
                        asc:
                          active_sort.column === "sku" &&
                          active_sort.direction === "asc",
                      })}
                    >
                      <span>SKU</span>
                      <ArrowIcon />
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        changeSort({
                          column: "name",
                          direction: checkDirectionToChange({
                            is_current_column: active_sort.column === "name",
                            active_direction: active_sort.direction,
                          }),
                        })
                      }
                      className={classNames("sort-button", {
                        active: active_sort.column === "name",
                        asc:
                          active_sort.column === "name" &&
                          active_sort.direction === "asc",
                      })}
                    >
                      <span>Nazwa produktu</span>
                      <ArrowIcon />
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={() =>
                      changeSort({
                        column: name,
                        direction: checkDirectionToChange({
                          is_current_column: active_sort.column === name,
                          active_direction: active_sort.direction,
                        }),
                      })
                    }
                    className={classNames("sort-button", {
                      active: active_sort.column === name,
                      asc:
                        active_sort.column === name &&
                        active_sort.direction === "asc",
                    })}
                  >
                    <span>{label}</span>
                    <ArrowIcon />
                  </button>
                )}

                <div className="m-t-5">
                  {predefined_sorts && (
                    <Select
                      options={predefined_sort_options}
                      onChange={(a) => {
                        if (a.value === "brak") {
                          setFilterValue({
                            name,
                            value: a.value,
                            label: a.value,
                          });
                        } else if (a.value === "s<") {
                          setFilterValue({
                            name,
                            value: `${name}_lower_than_suggested`,
                            label: "mniejsza niż sugerowana",
                          });
                        } else {
                          document.querySelector(`#sort_${name} input`).value =
                            a.value;
                          document.querySelector(`#sort_${name} input`).focus();
                        }
                      }}
                    />
                  )}
                </div>
              </th>
            ) : (
              <th style={style} key={name}>
                <span>{label}</span>
              </th>
            )
        )}
      </tr>
    </thead>
  );
};

export default TableSort;
