export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getBase64Data = async (file) => {
  const [base64_type, base64_data] = await toBase64(
    file
  ).then((base64) => base64.split(","));

  const data = [base64_type, file.name, base64_data].join(
    ","
  );
  return data;
};
