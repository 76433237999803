import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Dashboard,
  OrderLayout,
  PanelLoader,
  BtnMore,
  ModalWarning,
} from "components";

import useGet from "hooks/useGet";
import useUpdate from "hooks/useUpdate";
import useDelete from "hooks/useDelete";
import useCreate from "hooks/useCreate";
import { useAuth } from "context/providers/auth";

import renderDate from "utils/renderDate";

import OrderLines from "./components/OrderLines";
import ErrorBox from "./components/ErrorBox";
import OrderStatus from "./components/OrderStatus";
import OrderStatusSelect from "./components/OrderStatusSelect";
import Header from "./components/Header";
import MainInfo from "./components/MainInfo";
import WmsInfo from "./components/WmsInfo";
import DocumentInfo from "./components/DocumentInfo";
import Addresses from "./components/Adresses";
import BillingsList from "./components/BillingsList";
import WaybillsList from "./components/WaybillsList";
import LogsList from "./components/LogsList";

import { ReactComponent as ShopIcon } from "icons/shop.svg";
import OrderNotifications from "./components/OrderNotifications";
import OrderStatusesProvider from "context/providers/order_statuses";
import CloneOrderModal from "./components/CloneOrderModal";

const OrderEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [is_open_cancel_order_warning_modal, setOpenCancelOrderWarningModal] =
    useState(false);

  const [
    is_open_high_priority_reservation_warning_modal,
    setOpenHightPriorityReservationWarningModal,
  ] = useState(false);

  const [is_open_comment_read_warning_modal, setOpenCommentReadWarningModal] =
    useState(false);

  const [
    is_open_surcharge_zone_warning_modal,
    setOpenSurchargeZoneWarningModal,
  ] = useState(false);

  const [
    is_open_skip_client_phone_validation_modal,
    setOpenSkipClientPhoneValidationModal,
  ] = useState(false);

  const [
    is_open_skip_high_price_validation_modal,
    setOpenHighPriceValidationModal,
  ] = useState(false);

  const [is_open_clone_order_modal, setOpenCloneOrderModal] = useState(false);

  const [is_open_single_picking_mode_modal, setOpenSinglePickingModeModal] =
    useState(false);

  const [is_open_delete_order_warning_modal, setOpenDeleteOrderWarningModal] =
    useState(false);

  const { data, is_loading, is_error, is_fetching, refetch } = useGet({
    path: `/orders/${id}`,
    query: ["orders", id],
    context: "ui_show",
  });

  const standarization_errors = useMemo(
    () => data?.order_errors.filter(({ kind }) => kind === "standarization"),
    [data?.order_errors]
  );

  const validation_errors = useMemo(
    () => data?.order_errors.filter(({ kind }) => kind === "validation"),
    [data?.order_errors]
  );

  const reverify_errors = useMemo(
    () =>
      data?.order_errors.filter(({ kind }) =>
        ["verification", "verification_missings"].includes(kind)
      ),
    [data?.order_errors]
  );

  const reservation_errors = useMemo(
    () =>
      data?.order_errors.filter(({ kind }) =>
        ["reservation", "reservation_missings"].includes(kind)
      ),
    [data?.order_errors]
  );

  const export_packable_errors = useMemo(
    () => data?.order_errors.filter(({ kind }) => kind === "export_packable"),
    [data?.order_errors]
  );

  const invoice_errors = useMemo(
    () => data?.order_errors.filter(({ kind }) => kind === "issue_invoice"),
    [data?.order_errors]
  );

  const export_waybills_errors = useMemo(
    () => data?.order_errors.filter(({ kind }) => kind === "export_waybills"),
    [data?.order_errors]
  );

  const export_invoice_errors = useMemo(
    () => data?.order_errors.filter(({ kind }) => kind === "export_invoice"),
    [data?.order_errors]
  );

  const { is_saving: is_saving_confirming, create: confirmOrder } = useCreate({
    path: `/orders/${id}/confirm`,
    onSuccess: refetch,
    success_message: "Potwierdzono zamówienie",
  });

  const { is_saving: is_saving_restandarize, create: restandarize } = useCreate(
    {
      path: `/orders/${id}/restandarize`,
      onSuccess: refetch,
      success_message: "Ponowienie standaryzacji przebiegło pomyślnie",
    }
  );

  const { is_saving: is_saving_revalidate, create: revalidate } = useCreate({
    path: `/orders/${id}/revalidate`,
    onSuccess: refetch,
    success_message: "Ponowienie walidacji przebiegło pomyślnie",
  });

  const { is_saving: is_saving_reverify, create: reverify } = useCreate({
    path: `/orders/${id}/reverify`,
    onSuccess: refetch,
    success_message: "Ponowienie weryfikacji przebiegło pomyślnie",
  });

  const { is_saving: is_saving_rereserve, create: rereserve } = useCreate({
    path: `/orders/${id}/rereserve`,
    onSuccess: refetch,
    success_message: "Ponowienie rezerwacji przebiegło pomyślnie",
  });

  const { is_saving: is_saving_exported_packable, create: reExportPackable } =
    useCreate({
      path: `/orders/${id}/reexport_packable`,
      onSuccess: refetch,
      success_message:
        "Ponowienie exportu zamówienia do wms'a przebiegło pomyślnie",
    });

  const { is_saving: is_saving_reissue_invoice, create: reIssueInvoice } =
    useCreate({
      path: `/orders/${id}/reissue_invoice`,
      onSuccess: refetch,
      success_message: "Ponowienie fakturowania przebiegło pomyślnie",
    });

  const { is_saving: is_saving_reexport_invoice, create: reExportInvoice } =
    useCreate({
      path: `/orders/${id}/reexport_invoice`,
      onSuccess: refetch,
      success_message: "Ponowienie exportu faktur przebiegło pomyślnie",
    });

  const { is_saving: is_saving_reexport_waybills, create: reExportWaybills } =
    useCreate({
      path: `/orders/${id}/reexport_waybills`,
      onSuccess: refetch,
      success_message: "Ponowienie exportu listów przebiegło pomyślnie",
    });

  const { is_saving: is_canceling, create: cancelOrder } = useCreate({
    path: `/orders/${id}/cancel`,
    onSuccess: () => {
      setOpenCancelOrderWarningModal(false);
      refetch();
    },
    success_message: "Zamówienie zostało anulowane",
  });

  const {
    is_saving: is_setting_as_priority_reservation,
    update: setPriorityReservation,
  } = useUpdate({
    path: `/orders/${id}`,
    onSuccess: () => {
      setOpenCancelOrderWarningModal(false);
      refetch();
    },
    success_message:
      "Zamówienie zostało oznaczone jako priorytetowe podczas rezerwacji",
  });

  const { is_saving: is_saving_comment_read, update: confirmCommentRead } =
    useUpdate({
      path: `/orders/${id}`,
      onSuccess: () => {
        setOpenCommentReadWarningModal(false);
        refetch();
      },
      success_message: "Potwierdzono zapoznanie się z komentarzem klienta",
    });

  const {
    is_saving: is_saving_confirm_surcharge_zone,
    update: confirmSurchargeZone,
  } = useUpdate({
    path: `/orders/${id}`,
    onSuccess: () => {
      setOpenSurchargeZoneWarningModal(false);
      refetch();
    },
    success_message: "Potwierdzono wysyłkę w strefę z dopłatami",
  });

  const {
    is_saving: is_saving_skip_client_phone_validation,
    update: confirmSkipClientPhoneValidation,
  } = useUpdate({
    path: `/orders/${id}`,
    onSuccess: () => {
      setOpenSkipClientPhoneValidationModal(false);
      refetch();
    },
    success_message: "Potwierdzono pominięcie walidacji numeru telefonu",
  });
  const {
    is_saving: is_saving_skip_high_price_validation,
    create: confirmSkipHighPriceValidation,
  } = useCreate({
    path: `/orders/${id}/skip_high_price_validation`,
    onSuccess: () => {
      setOpenHighPriceValidationModal(false);
      refetch();
    },
    success_message: "Potwierdzono pominięcie walidacji wysokiej ceny",
  });

  const {
    is_saving: is_saving_single_picking_mode,
    update: confirmSinglePickingMode,
  } = useUpdate({
    path: `/orders/${id}`,
    onSuccess: () => {
      setOpenSinglePickingModeModal(false);
      refetch();
    },
    success_message: "Potwierdzono wymuszenie pojedyńczej kompletacji",
  });

  const { is_saving: is_deleting, _delete: deleteOrder } = useDelete({
    path: `/orders/${id}`,
    onSuccess: () => {
      setOpenDeleteOrderWarningModal(false);
      navigate("/orders");
    },
    success_message: "Potwierdzono pominięcie walidacji numeru telefonu",
  });

  if (is_loading || is_error) {
    return (
      <OrderLayout
        show_statuses={true}
        page={{
          name: "Trwa ładowanie...",
          icon: <ShopIcon />,
          breadcrumbs: [
            {
              name: "Zamówienia",
              path: "/orders",
            },
          ],
        }}
      >
        <PanelLoader />
      </OrderLayout>
    );
  }

  return (
    <OrderLayout
      show_statuses={true}
      status_id={data?.status?.id}
      page={{
        name: `Zamówienie ${data.id}`,
        icon: <ShopIcon />,
        breadcrumbs: [
          {
            name: "Zamówienia",
            path: "/orders",
          },
          {
            name: data.id,
          },
        ],
      }}
    >
      <div className="order-details">
        <Header
          id={data.id}
          external_id={data.external_id}
          delivery_fullname={data.delivery_fullname}
          ordered_at={data.ordered_at}
          created_at={data.created_at}
        />
        <OrderLines
          data={data.order_lines}
          refetch={refetch}
          currency_code={data.currency_code}
          errors={data?.order_errors || []}
          // can_edit={!data?.erp_reservation_number}
          can_edit={true}
        />
        <div className="row">
          <div className="box-9">
            <div className="row">
              <div className="box-7">
                <MainInfo
                  {...data}
                  refetch={refetch}
                  is_fetching={is_fetching}
                />
              </div>
              <div className="box-5">
                <WmsInfo
                  {...data}
                  refetch={refetch}
                  is_fetching={is_fetching}
                />
                <DocumentInfo {...data} />
              </div>
            </div>
            <Addresses {...data} refetch={refetch} is_fetching={is_fetching} />
            <div className="row">
              <div className="box-4">
                <BillingsList data={data.billings} />
              </div>
              <div className="box-4">
                <WaybillsList data={data.waybills} />
              </div>
              <div className="box-4">
                <OrderNotifications data={data.order_notifications} />
              </div>
            </div>
          </div>
          <div className="box-3">
            <Dashboard
              header={{
                title: "Postęp",
                extra: [47, 50, 51, 52, 32, 33, 34, 48].includes(
                  data?.status?.id
                ) ? (
                  <OrderStatusSelect refetch={refetch} {...data.status} />
                ) : (
                  <>
                    <OrderStatus {...data.status} />
                    <BtnMore
                      actions={[
                        [59].includes(data?.status?.id) && {
                          name: "Usuń zamówienie",
                          action: () => setOpenDeleteOrderWarningModal(true),
                        },
                        (user.is_admin ||
                          [26, 71, 27, 29, 30, 62, 64, 66, 77, 79].includes(
                            data?.status?.id
                          )) && {
                          name: "Anuluj zamówienie",
                          action: () => setOpenCancelOrderWarningModal(true),
                        },
                        !data?.high_priority_reservation &&
                          [
                            59, 60, 61, 62, 63, 64, 65, 66, 67, 76, 77, 78,
                          ].includes(data?.status?.id) && {
                            name: "Priorytetowa rezerwacja",
                            action: () =>
                              setOpenHightPriorityReservationWarningModal(true),
                          },
                        data?.status?.id === 64 &&
                          data?.client_comment?.length > 0 &&
                          !data?.is_comment_read && {
                            name: "Zapoznano się z komentarzem klienta",
                            action: () => setOpenCommentReadWarningModal(true),
                          },
                        data?.status?.id === 79 &&
                          data?.order_errors?.filter(
                            ({ kind, field_name }) =>
                              kind === "reservation" &&
                              field_name === "total_price"
                          )?.length > 0 &&
                          !data?.skip_high_price_validation && [
                            {
                              name: "Pomiń weryfikację wysokiej ceny",
                              action: () =>
                                setOpenHighPriceValidationModal(true),
                            },
                            {
                              name: "Utwórz kopię zamówienia",
                              action: () => setOpenCloneOrderModal(true),
                            },
                          ],
                        data?.status?.id === 64 &&
                          data?.need_confirm_surcharge_zone &&
                          !data?.is_confirmed_surcharge_zone && {
                            name: "Potwierdzam wysyłkę w strefę z dopłatami",
                            action: () =>
                              setOpenSurchargeZoneWarningModal(true),
                          },
                        data?.status?.id === 64 &&
                          !data?.skip_client_phone_validation && {
                            name: "Pomiń walidację numeru telefonu",
                            action: () =>
                              setOpenSkipClientPhoneValidationModal(true),
                          },
                        [
                          59, 60, 61, 62, 63, 64, 65, 66, 67, 76, 77, 78, 79,
                        ].includes(data?.status?.id) &&
                          !data?.single_picking_mode &&
                          !data.exported_packable_at && {
                            name: "Wymuś pojedyńczą kompletacje",
                            action: () => setOpenSinglePickingModeModal(true),
                          },
                        !data?.high_priority_reservation &&
                          [
                            59, 60, 61, 62, 63, 64, 65, 66, 67, 76, 77, 78,
                          ].includes(data?.status?.id) && {
                            name: "Priorytetowa rezerwacja",
                            action: () =>
                              setOpenHightPriorityReservationWarningModal(true),
                          },
                      ]
                        .filter(Boolean)
                        .flat()}
                    />
                  </>
                ),
              }}
            >
              {[59, 60].includes(data?.status?.id) &&
                data.order_lines.length > 0 && (
                  <div className="p-t-10 p-l-10 p-r-10">
                    <button
                      onClick={() => confirmOrder()}
                      type="button"
                      className="btn btn-green btn-sm btn-full"
                      disabled={is_saving_confirming}
                    >
                      <span>
                        {is_saving_confirming
                          ? "Proszę czekać"
                          : "Potwierdź zamówienie"}
                      </span>
                    </button>
                  </div>
                )}
              <div className="p-10">
                <ErrorBox
                  title="Standaryzacja"
                  date={data.standarized_at}
                  data={standarization_errors}
                  aside={
                    [62, 64, 66, 67].includes(data?.status?.id) ? (
                      <button
                        disabled={is_saving_restandarize}
                        type="button"
                        className="btn btn-sm btn-blue"
                        onClick={() => restandarize()}
                      >
                        {is_saving_restandarize ? "Proszę czekać..." : "Ponów"}
                      </button>
                    ) : (
                      renderDate(data.standarized_at)
                    )
                  }
                />
                <ErrorBox
                  title="Walidacja"
                  date={data.validated_at}
                  data={validation_errors}
                  aside={
                    data?.status?.id === 64 ? (
                      <button
                        disabled={is_saving_revalidate}
                        type="button"
                        className="btn btn-sm btn-blue"
                        onClick={() => revalidate()}
                      >
                        {is_saving_revalidate ? "Proszę czekać..." : "Ponów"}
                      </button>
                    ) : (
                      renderDate(data.validated_at)
                    )
                  }
                />
                <ErrorBox
                  title="Weryfikacja"
                  date={data.verified_at}
                  data={reverify_errors}
                  aside={
                    data?.status?.id === 77 ? (
                      <button
                        disabled={is_saving_reverify}
                        type="button"
                        className="btn btn-sm btn-blue"
                        onClick={() => reverify()}
                      >
                        {is_saving_reverify ? "Proszę czekać..." : "Ponów"}
                      </button>
                    ) : (
                      renderDate(data.verified_at)
                    )
                  }
                />
                <ErrorBox
                  title="Rezerwacja"
                  date={data.reserved_at}
                  data={reservation_errors}
                  aside={
                    data?.status?.id === 66 ? (
                      <button
                        disabled={is_saving_rereserve}
                        type="button"
                        className="btn btn-sm btn-blue"
                        onClick={() => rereserve()}
                      >
                        {is_saving_rereserve ? "Proszę czekać..." : "Ponów"}
                      </button>
                    ) : (
                      renderDate(data.reserved_at)
                    )
                  }
                />
                <ErrorBox
                  title="Export na pakowalnie"
                  date={data.exported_packable_at}
                  data={export_packable_errors}
                  aside={
                    <>
                      {Boolean(data.exported_packable_at)
                        ? renderDate(data.exported_packable_at)
                        : "-"}
                      {[71, 27, 29, 30].includes(data?.status?.id) && (
                        <button
                          style={{
                            marginLeft: 6,
                            padding: "5px 7px",
                          }}
                          disabled={is_saving_exported_packable}
                          type="button"
                          className="btn btn-sm btn-blue"
                          onClick={() => reExportPackable()}
                        >
                          {is_saving_exported_packable ? "..." : "Ponów"}
                        </button>
                      )}
                    </>
                  }
                />
                <ErrorBox
                  title="Spakowane"
                  date={data.packed_at}
                  data={[]}
                  aside={renderDate(data.packed_at)}
                />
                <ErrorBox
                  title="Export listów"
                  date={data.exported_waybills_at}
                  data={export_waybills_errors}
                  aside={
                    data?.status?.id === 73 ? (
                      <button
                        disabled={is_saving_reexport_waybills}
                        type="button"
                        className="btn btn-sm btn-blue"
                        onClick={() => reExportWaybills()}
                      >
                        {is_saving_reexport_waybills
                          ? "Proszę czekać..."
                          : "Ponów"}
                      </button>
                    ) : (
                      renderDate(data.exported_waybills_at)
                    )
                  }
                />
                <ErrorBox
                  title="Fakturowanie"
                  date={data.invoiced_at}
                  data={invoice_errors}
                  aside={
                    data?.status?.id === 69 ? (
                      <button
                        disabled={is_saving_reissue_invoice}
                        type="button"
                        className="btn btn-sm btn-blue"
                        onClick={() => reIssueInvoice()}
                      >
                        {is_saving_reissue_invoice
                          ? "Proszę czekać..."
                          : "Ponów"}
                      </button>
                    ) : (
                      renderDate(data.invoiced_at)
                    )
                  }
                />
                <ErrorBox
                  title="Eksport faktury"
                  date={data.exported_invoice_at}
                  data={export_invoice_errors}
                  aside={
                    data?.status?.id === 75 ? (
                      <button
                        disabled={is_saving_reexport_invoice}
                        type="button"
                        className="btn btn-sm btn-blue"
                        onClick={() => reExportInvoice()}
                      >
                        {is_saving_reexport_invoice
                          ? "Proszę czekać..."
                          : "Ponów"}
                      </button>
                    ) : (
                      renderDate(data.exported_invoice_at)
                    )
                  }
                />
                <ErrorBox
                  title="Zakończone"
                  date={data.completed_at}
                  data={[]}
                  aside={renderDate(data.completed_at)}
                />
              </div>
            </Dashboard>
            <LogsList data={data.logs} />
          </div>
        </div>
      </div>
      {is_open_cancel_order_warning_modal && (
        <ModalWarning
          content={
            <>
              Czy na pewno chcesz anlować zamówienie <strong>{data.id}</strong>?
            </>
          }
          is_loading={is_canceling}
          handleConfirm={() => cancelOrder()}
          handleClose={() => setOpenCancelOrderWarningModal(false)}
        />
      )}
      {!data?.high_priority_reservation &&
        is_open_high_priority_reservation_warning_modal && (
          <ModalWarning
            content="Czy na pewno chcesz oznaczyć zamównie jako priorytetowe podczas rezerwacji?"
            is_loading={is_setting_as_priority_reservation}
            handleConfirm={() =>
              setPriorityReservation({
                high_priority_reservation: true,
              })
            }
            handleClose={() =>
              setOpenHightPriorityReservationWarningModal(false)
            }
          />
        )}
      {is_open_comment_read_warning_modal && (
        <ModalWarning
          content="Czy na pewno chcesz potwierdzić zapoznanie się z komentarzem klienta?"
          is_loading={is_saving_comment_read}
          handleConfirm={() =>
            confirmCommentRead({
              is_comment_read: true,
            })
          }
          handleClose={() => setOpenHightPriorityReservationWarningModal(false)}
        />
      )}
      {is_open_surcharge_zone_warning_modal && (
        <ModalWarning
          content="Czy na pewno chcesz potwierdzić wysyłkę w strefę z dopłatami?"
          is_loading={is_saving_confirm_surcharge_zone}
          handleConfirm={() =>
            confirmSurchargeZone({
              is_confirmed_surcharge_zone: true,
            })
          }
          handleClose={() => setOpenSurchargeZoneWarningModal(false)}
        />
      )}
      {is_open_skip_client_phone_validation_modal && (
        <ModalWarning
          content="Czy na pewno chcesz pominąć walidację numeru telefonu?"
          is_loading={is_saving_skip_client_phone_validation}
          handleConfirm={() =>
            confirmSkipClientPhoneValidation({
              skip_client_phone_validation: true,
            })
          }
          handleClose={() => setOpenSkipClientPhoneValidationModal(false)}
        />
      )}
      {is_open_skip_high_price_validation_modal && (
        <ModalWarning
          content="Czy na pewno chcesz pominąć walidację wysokiej ceny?"
          is_loading={is_saving_skip_high_price_validation}
          handleConfirm={() => confirmSkipHighPriceValidation()}
          handleClose={() => setOpenHighPriceValidationModal(false)}
        />
      )}
      {is_open_clone_order_modal && (
        <CloneOrderModal
          order_id={id}
          handleClose={() => setOpenCloneOrderModal(false)}
        />
      )}
      {is_open_delete_order_warning_modal && (
        <ModalWarning
          content="Czy na pewno chcesz usunąć zamówienie?"
          is_loading={is_deleting}
          handleConfirm={() => deleteOrder()}
          handleClose={() => setOpenDeleteOrderWarningModal(false)}
        />
      )}
      {is_open_single_picking_mode_modal && (
        <ModalWarning
          content="Czy na pewno chcesz wymusić pojedyńczą kompletacje?"
          is_loading={is_saving_single_picking_mode}
          handleConfirm={() =>
            confirmSinglePickingMode({
              single_picking_mode: true,
            })
          }
          handleClose={() => setOpenSinglePickingModeModal(false)}
        />
      )}
    </OrderLayout>
  );
};

const OrderEditContext = () => (
  <OrderStatusesProvider>
    <OrderEdit />
  </OrderStatusesProvider>
);
export default OrderEditContext;
