import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "expano-components";
import ImportBillingsModal from "./components/ImportBillingsModal";

const PagePanel = ({ data }) => {
  const [is_open_import_modal, setOpenImportModal] = useState(false);
  return (
    <div className="waybill-panel">
      <div className="waybill-panel__info">
        <div className="info-holder">
          {!!data?.icon && (
            <div className="info-holder__icon">{data?.icon}</div>
          )}
          <div className="info-holder__text">
            <p className="title">{data?.name}</p>
            <ul className="breadcrumbs">
              <li>
                <Link to="/">OMS</Link>
              </li>
              {data?.breadcrumbs?.map(({ path, name }) => (
                <li key={name}>
                  {!!path ? <Link to={path}>{name}</Link> : <span>{name}</span>}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="waybill-panel__action">
        <Button
          type="add"
          text="Importuj rozliczenia"
          onClick={() => setOpenImportModal(true)}
        />
        {is_open_import_modal && (
          <ImportBillingsModal handleClose={() => setOpenImportModal(false)} />
        )}
      </div>
    </div>
  );
};

export default PagePanel;
