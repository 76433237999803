import { useState } from "react";

import { Link, useLocation } from "react-router-dom";
import {
  TopBar,
  Sidebar,
} from "expano-components";

import PagePanel from "./components/PagePanel";

import nav from "utils/nav";
import { useAuth } from "providers/auth";

const WaybillLayout = ({ children, page }) => {
  const location = useLocation();
  const [is_open_sidebar, setOpenSidebar] = useState(false);
  const {
    user: { email, company, is_admin },
    handleLogout,
  } = useAuth();
  const toggleSidebar = () =>
    setOpenSidebar(!is_open_sidebar);

  const nav_by_role = nav
    .map((el) => ({
      ...el,
      items: el.items.filter((item) =>
        item.protected ? is_admin : true
      ),
    }))
    .filter((el) => el.items.length > 0);

  return (
    <>
      <Sidebar
        Layout="oms"
        Link={Link}
        location={location}
        nav={nav_by_role}
        is_open={is_open_sidebar}
      />
      <main className="main-container">
        <TopBar
          handleLogout={handleLogout}
          is_open={is_open_sidebar}
          toggleSidebar={toggleSidebar}
          currentUser={{ email, company: company?.name }}
        />
        <div className="main-dashboard">
          {page && (
            <PagePanel app="oms" Link={Link} data={page} />
          )}
          {children}
        </div>
      </main>
    </>
  );
};

export default WaybillLayout;
