import React from "react";

import ReactSelect from "react-select";

const styles = {
  control: (styles) => ({
    ...styles,
    borderColor: "#D7D7DA",
    minHeight: 12,
    padding: "0 5px",
    borderRadius: 2,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  option: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "9px",
    lineHeight: "12px",
    padding: 5,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "9px",
    lineHeight: "12px",
    padding: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "9px",
    lineHeight: "12px",
    color: "#D7D7DA",
  }),
};

const Select = ({ onChange, options = [] }) => {
  return (
    <ReactSelect
      className="filters-select"
      onChange={onChange}
      styles={styles}
      options={options}
      value={null}
      placeholder="Wybierz..."
    />
  );
};

export default Select;
