import React, { useState } from "react";
import LazyLoad from "react-lazy-load";
import { Label, ModalPreview } from "expano-components";

import { useProductsAnalyze } from "../../../../context/provider";

const formatNumber = (num) => {
  return num
    .toFixed(2) // Ensure two decimal places
    .replace(".", ",") // Replace decimal point with a comma
    .replace(/\B(?=(\d{3})+(?!\d))/g, " "); // Add spaces as thousand separators
};

const TableRows = () => {
  const [preview_image, setPreviewImage] = useState(null);
  const {
    state: { products },
    setFilterValue,
  } = useProductsAnalyze();

  return (
    <>
      {preview_image && (
        <ModalPreview
          src={preview_image}
          handleClose={() => setPreviewImage(null)}
        />
      )}
      <tbody>
        {products?.map(
          ({
            id,
            expano_root_id,
            sku,
            name,
            product_group_sku,
            quantity,
            total_net_costs,
            total_net_income,
            total_net_profit,
            margin,
            average_net_price,
            average_net_profit,
            average_net_cost,
            image_url,
            image_thumb_url,
            producer_name,
            producer_code,
            supplier_name,
            supplier_code,
            root_taxon_name,
            root_taxon_path_name,
          }) => (
            <tr key={id}>
              <td>
                <div className="row no-wrap ai-c">
                  {image_thumb_url ? (
                    <LazyLoad height={50} width={50}>
                      <button
                        type="button"
                        className="btn-preview"
                        onClick={() => setPreviewImage(image_url)}
                        style={{
                          backgroundImage: `url(${image_thumb_url})`,
                        }}
                      />
                    </LazyLoad>
                  ) : (
                    <div className="btn-preview-blank" />
                  )}
                  <div className="p-l-10">
                    <p>
                      <strong>{sku}</strong>{" "}
                      {product_group_sku && (
                        <>
                          (
                          <span
                            className="text-muted text-hoverable"
                            onClick={() =>
                              setFilterValue({
                                name: "name_sku_or_product_group_sku",
                                value: product_group_sku,
                                label: product_group_sku,
                              })
                            }
                          >
                            {product_group_sku}
                          </span>
                          )
                        </>
                      )}
                    </p>
                    <p style={{ maxWidth: 500 }}>{name}</p>
                    <div className="labels-group">
                      <a
                        href={`https://root.tradesk.pl/products/${expano_root_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label type="bordered" color="#fd397a" text="ROOT" />
                      </a>
                      <a
                        href={`https://pim.tradesk.pl/products/search?expano_root_id=${expano_root_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label type="bordered" color="#36bcff" text="PIM" />
                      </a>
                      <a
                        href={`https://wms.tradesk.pl/products/find_by_code?code=${sku}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Label type="bordered" color="#ae22ff" text="WMS" />
                      </a>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                {root_taxon_name ? (
                  <>
                    <p>
                      <span
                        className="text-hoverable"
                        onClick={() =>
                          setFilterValue({
                            name: "root_taxon_name",
                            value: root_taxon_name,
                            label: root_taxon_name,
                          })
                        }
                      >
                        {root_taxon_name}
                      </span>
                    </p>
                    <p className="text-muted">{root_taxon_path_name}</p>
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td>
                {producer_name && (
                  <p>
                    <span
                      className="text-hoverable"
                      onClick={() =>
                        setFilterValue({
                          name: "producer_name",
                          value: producer_name,
                          label: producer_name,
                        })
                      }
                    >
                      {producer_name}
                    </span>
                  </p>
                )}
                <p className="text-muted">{producer_code}</p>
              </td>
              <td>
                {supplier_name && (
                  <p>
                    <span
                      className="text-hoverable"
                      onClick={() =>
                        setFilterValue({
                          name: "supplier_name",
                          value: supplier_name,
                          label: supplier_name,
                        })
                      }
                    >
                      {supplier_name}
                    </span>
                  </p>
                )}
                <p className="text-muted">{supplier_code}</p>
              </td>
              <td>{quantity} szt.</td>
              <td>{formatNumber(total_net_income)} PLN</td>
              <td>{formatNumber(total_net_profit)} PLN</td>
              <td>{formatNumber(total_net_costs)} PLN</td>
              <td>{margin.toFixed(2)}%</td>
              <td>{formatNumber(average_net_price)} PLN</td>
              <td>{formatNumber(average_net_profit)} PLN</td>
              <td>{formatNumber(average_net_cost)} PLN</td>
            </tr>
          )
        )}
      </tbody>
    </>
  );
};

export default TableRows;
