import React from "react";
import { Layout } from "components";
import { ReactComponent as ChartIcon } from "icons/chart.svg";
import ProductsAnalyzeProvider from "./context/provider";
import Table from "./components/Table";
import Pagination from "./components/Pagination";
import GlobalFilters from "./components/GlobalFilters";

// # Analiza trendów

// # Analiza wartości - obecny widok

const ProductsAnalyze = () => (
  <ProductsAnalyzeProvider>
    <Layout
      page={{
        name: "Analiza produktów",
        icon: <ChartIcon />,
        breadcrumbs: [
          {
            name: "Analiza",
          },
        ],
      }}
    >
      <div className="main-list">
        <GlobalFilters />
        <Table />
        <Pagination />
      </div>
    </Layout>
  </ProductsAnalyzeProvider>
);

export default ProductsAnalyze;
