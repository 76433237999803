import { useSearchParams } from "react-router-dom";
import { MainList, WaybillLayout } from "components";
import { columns, table_filters, main_filters } from "./const/data";
import { ReactComponent as WaybillsIcon } from "icons/waybills.svg";
import Item from "../components/Item";

const WaybillsList = () => {
  const [search_params] = useSearchParams();
  const search = search_params.get("search");

  const sortable_columns = columns.filter(
    ({ sortable }) => sortable === true
  );
  const sort_options = sortable_columns.map(({label, name}) => (
    { label: label, value: name})
  );
  const default_sort = sortable_columns.filter(
    ({ default_sort }) => default_sort === true
  )[0]?.name || 'id';

  return (
    <WaybillLayout
      page={{
        name: "Listy przewozowe",
        icon: <WaybillsIcon />,
        breadcrumbs: [
          {
            name: "Listy przewozowe",
          },
        ],
      }}
    >
      <MainList
        title={search ? `Wyniki wyszukiwania '${search}'` : 'Wszystkie listy przewozowe'}
        query_key={['waybills']}
        api_path={"/waybills"}
        search_type="elastic"
        sort_options={sort_options}
        sort_column={default_sort}
        sort_direction="desc"
        default_per_page={20}
        sort={columns}
        table_filters={table_filters}
        main_filters={main_filters}
        renderItem={Item}
        empty_text="Brak listów przewozowych dla podanych parametrów"
      />
    </WaybillLayout>
  );
};

export default WaybillsList;
