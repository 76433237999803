import React, { useMemo, useReducer, useContext, useEffect } from "react";
import { api } from "utils/api";
import moment from "moment";

import reducer, {
  initial_state,
  DATA_LOADING,
  CHANGE_SORT,
  SET_FILTER_VALUE,
  SET_FAST_FILTER_VALUE,
  REMOVE_FILTER_VALUE,
  DATA_FAILURE,
  DATA_SUCCESS,
  SET_META_PAGINATION,
  FILTER_PRODUCTS,
  SET_GLOBAL_FILTER_VALUE,
} from "./reducer";

// CONTEXT ===================================
const ProductsAnalyzeContext = React.createContext();

const sort = [
  {
    label: "Produkt",
    name: "name_sku_or_product_group_sku",
    predefined_sorts: false,
  },
  {
    label: "Kategoria",
    name: "root_taxon_name",
    style: { width: 200 },
    predefined_sorts: false,
  },
  {
    label: "Producent",
    name: "producer_name",
    style: { width: 130 },
    predefined_sorts: false,
  },
  {
    label: "Dostawca",
    name: "supplier_name",
    style: { width: 130 },
    predefined_sorts: false,
  },
  {
    label: "Ilość sprzedaży",
    name: "quantity",
    style: { width: 150 },
    predefined_sorts: true,
  },
  {
    label: "Wartość sprzedaży",
    name: "total_net_income",
    style: { width: 150 },
    predefined_sorts: true,
  },
  {
    label: "Zysk",
    name: "total_net_profit",
    style: { width: 120 },
    predefined_sorts: true,
  },
  {
    label: "Koszty",
    name: "total_net_costs",
    style: { width: 120 },
    predefined_sorts: true,
  },
  {
    label: "Marża",
    name: "margin",
    style: { width: 120 },
    predefined_sorts: true,
  },
  {
    label: "Średnia cena",
    name: "average_net_price",
    style: { width: 120 },
    predefined_sorts: true,
  },
  {
    label: "Średni zysk",
    name: "average_net_profit",
    style: { width: 120 },
    predefined_sorts: true,
  },
  {
    label: "Średni koszt",
    name: "average_net_cost",
    style: { width: 120 },
    predefined_sorts: true,
  },
];

function ProductsAnalyzeProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initial_state);

  useEffect(() => {
    getData(state.global_filters);
  }, [state.global_filters]);

  const getData = async (filters) => {
    try {
      const range_from = filters.range_from;
      const range_to = filters?.range_to;
      dispatch({ type: DATA_LOADING });
      const { data } = await api.get(
        `/analyze/products?${
          range_from ? `range_from=${moment(range_from).format()}` : ""
        }${range_to ? `range_to=${moment(range_to).format()}` : ""}`
      );
      dispatch({
        type: DATA_SUCCESS,
        payload: {
          data,
        },
      });
    } catch (error) {
      dispatch({ type: DATA_FAILURE });
      if (error.response.status === 403) {
        window.location.pathname = "/";
      }
    }
  };

  const changeSort = ({ column, direction }) =>
    dispatch({
      type: CHANGE_SORT,
      payload: { column, direction },
    });

  const setFilterValue = ({ name, value, label }) => {
    dispatch({
      type: SET_FILTER_VALUE,
      payload: { name, value, label },
    });
    dispatch({ type: FILTER_PRODUCTS });
  };

  const setFastFilterValue = ({ name, value }) => {
    dispatch({
      type: SET_FAST_FILTER_VALUE,
      payload: { name, value },
    });
    dispatch({ type: FILTER_PRODUCTS });
  };

  const setGlobalFiltersValue = (filters) => {
    dispatch({
      type: SET_GLOBAL_FILTER_VALUE,
      payload: filters,
    });
    dispatch({ type: FILTER_PRODUCTS });
  };

  const removeFilterValue = ({ name, value }) => {
    dispatch({
      type: REMOVE_FILTER_VALUE,
      payload: { name, value },
    });
    dispatch({ type: FILTER_PRODUCTS });
  };

  const setMetaPagination = ({ page, per_page }) =>
    dispatch({ type: SET_META_PAGINATION, payload: { page, per_page } });

  const value = useMemo(() => {
    return {
      state,
      sort,
      changeSort,
      setFilterValue,
      setFastFilterValue,
      setGlobalFiltersValue,
      removeFilterValue,
      setMetaPagination,
    };
  }, [state]);

  return (
    <ProductsAnalyzeContext.Provider value={value}>
      {children}
    </ProductsAnalyzeContext.Provider>
  );
}

const useProductsAnalyze = () => useContext(ProductsAnalyzeContext);
export { useProductsAnalyze };
export default ProductsAnalyzeProvider;
